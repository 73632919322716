// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_s6";
export var lbContainerInner = "y_s7";
export var movingForwards = "y_s8";
export var movingForwardsOther = "y_s9";
export var movingBackwards = "y_tb";
export var movingBackwardsOther = "y_tc";
export var lbImage = "y_td";
export var lbOtherImage = "y_tf";
export var prevButton = "y_tg";
export var nextButton = "y_th";
export var closing = "y_tj";
export var appear = "y_tk";