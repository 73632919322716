// extracted by mini-css-extract-plugin
export var articleWrapper = "l_pq";
export var articleText = "l_pr g_fv g_ds";
export var header = "l_ps g_b2 g_bz";
export var headerImageWrapper = "l_pt g_ch g_b3 g_cc g_cb g_b7 g_cd g_ck g_cR";
export var headerGradient = "l_pv g_ch g_b3 g_cc g_cb g_b7 g_cd";
export var headerGradientOverlay = "l_pw g_ch g_b3 g_cc g_cb g_b7 g_cd";
export var headerContentWrapper = "l_px g_cz g_cP g_cD g_cJ g_cl g_b2";
export var contentWrapper = "l_q g_bz";
export var dateTag = "l_py g_df g_dr g_fq g_bz";
export var icon1 = "l_pz g_dP";
export var icon2 = "l_pB g_dP g_dJ";
export var tagBtn = "l_pC g_c1 g_dD g_fp g_fB";
export var headerText = "l_pD g_bz g_df g_ds g_cW";
export var center = "l_pF g_fw";
export var videoIframeStyle = "l_pG g_f0 g_bz g_bL g_cy g_c1 g_bV";
export var articleImageWrapper = "l_pH g_ds g_b2";
export var articleImageWrapperIcon = "l_pJ g_ds g_b2";
export var articleRow = "l_pK g_cD g_z";
export var quoteWrapper = "l_hG g_cC g_cP g_ds";
export var quoteBar = "l_pL g_bL";
export var quoteText = "l_pM";
export var authorBox = "l_pN g_bz";
export var authorRow = "l_pP g_cD g_cW g_dy";
export var separator = "l_pQ g_bz";
export var line = "l_gd g_bz g_gd g_dY g_dy";
export var authorImage = "l_l7 g_c7 g_b2 g_bz g_dt";
export var authorText = "l_pR g_dh g_dt";
export var masonryImageWrapper = "l_pS";
export var bottomSeparator = "l_pT g_bz g_dy";
export var linksWrapper = "l_pV g_dh g_fv";
export var comments = "l_pW g_cD g_dy";
export var sharing = "l_pX g_cz g_cD g_cJ";
export var shareText = "l_pY g_bz g_fw";
export var icon = "l_pZ";
export var text = "l_p0";
export var SubtitleSmall = "l_p1";
export var SubtitleNormal = "l_p2";
export var SubtitleLarge = "l_p3";