// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_qZ g_gj g_cG g_fv";
export var alignLeft = "r_p4 g_gj g_cG g_fv";
export var alignDiscCenter = "r_q0 g_gk g_cD g_fw";
export var alignCenter = "r_cP g_gk g_cD g_fw";
export var alignDiscRight = "r_q1 g_gl g_cH g_fx";
export var alignRight = "r_p5 g_gl g_cH g_fx";
export var footerContainer = "r_q2 g_fS g_cW";
export var footerContainerFull = "r_q3 g_fQ g_cW";
export var footerHeader = "r_kY g_kY";
export var footerTextWrapper = "r_q4 g_bz";
export var footerDisclaimerWrapper = "r_k3 g_k3 g_dk";
export var badgeWrapper = "r_q5 g_bz g_cz g_cP g_cF g_cJ";
export var footerDisclaimerRight = "r_k4 g_k4 g_cz";
export var footerDisclaimerLeft = "r_k5 g_k5 g_cz";
export var verticalTop = "r_q6 g_cz g_cJ g_cN g_cL";
export var firstWide = "r_q7";
export var disclaimer = "r_q8";
export var socialDisclaimer = "r_q9";
export var left = "r_rb";
export var wide = "r_rc g_dy";
export var right = "r_rd g_cK";
export var line = "r_gd g_gf g_dv";
export var badgeDisclaimer = "r_rf g_cC g_cP g_cJ";
export var badgeContainer = "r_rg g_cz g_cH g_cP";
export var badge = "r_rh";
export var padding = "r_rj g_d7";
export var end = "r_rk g_cH";
export var linkWrapper = "r_rl g_fB";
export var link = "r_s g_fB";
export var colWrapper = "r_rm g_dx";
export var media = "r_rn g_cx g_fy";
export var itemRight = "r_rp";
export var itemLeft = "r_rq";
export var itemCenter = "r_rr";
export var exceptionWeight = "r_rs w_r9";