// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qG g_hn g_ds";
export var heroHeaderCenter = "q_hp g_hp g_ds g_fw";
export var heroHeaderRight = "q_hq g_hq g_ds g_fx";
export var heroParagraphLeft = "q_qH g_hk g_dw";
export var heroParagraphCenter = "q_hl g_hl g_dw g_fw";
export var heroParagraphRight = "q_hm g_hm g_dw g_fx";
export var heroBtnWrapperLeft = "q_qJ g_m g_fX g_bz g_cz g_cG";
export var heroBtnWrapperCenter = "q_qK g_fY g_fX g_bz g_cz g_cD";
export var heroBtnWrapperRight = "q_qL g_fZ g_fX g_bz g_cz g_cH";
export var overlayBtnWrapper = "q_qM g_hj g_b3 g_cb g_cc g_cd g_cn g_dc";
export var design4 = "q_qN g_hh g_b3 g_cb g_cc g_cn";
export var heroExceptionSmall = "q_qP w_qP";
export var heroExceptionNormal = "q_qQ w_qQ";
export var heroExceptionLarge = "q_qR w_qR";
export var Title1Small = "q_qS w_qS w_rK w_rL";
export var Title1Normal = "q_qT w_qT w_rK w_rM";
export var Title1Large = "q_qV w_qV w_rK w_rN";
export var BodySmall = "q_qW w_qW w_rK w_r2";
export var BodyNormal = "q_qX w_qX w_rK w_r3";
export var BodyLarge = "q_qY w_qY w_rK w_r4";